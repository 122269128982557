import React from 'react';

const ResumeDownload = () => {
  return (
    <div class="flex justify-end w-full flex-wrap max-w-screen-md mx-auto pt-12 pr-5">
      <button class="uppercase  bg-indigo-900 text-me font-semibold py-2 px-4 rounded ">
        <a
          href="https://drive.google.com/uc?export=download&id=1hsVPe6XUdBmEevAqYNw1yiOWsM8iNZk9"
          class="text-white hover:text-pink-500 "
        >
          download my cv
        </a>
      </button>
    </div>
  );
};

export default ResumeDownload;
